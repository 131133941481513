

















  import {
    Vue, Component, Prop,
  } from 'nuxt-property-decorator'
  // @ts-ignore
  import { HalfCircleSpinner } from 'epic-spinners/dist/lib/epic-spinners.min'

  @Component({
    components: {
      HalfCircleSpinner,
    },
  })
  export default class OneIconLoader extends Vue {
    @Prop({
      type: Boolean,
      required: true,
    })
    readonly isReady!: boolean;

    @Prop({
      type: Number,
      required: false,
      default: 30,
    })
    readonly size!: number;

    @Prop({
      type: String,
      required: false,
      default: '#ff1d5e',
    })
    readonly color!: string;

    @Prop({
      type: String,
      required: false,
    })
    readonly icon?: string;
  }
